import styled from "@emotion/styled";
import { Card, Typography } from "@mui/material";
import { ResygnalTheme } from "../../ResygnalTheme";

export const BackgroundImg = styled.div`
  border-radius: 15px;
  background: linear-gradient(${ResygnalTheme.dark}, ${ResygnalTheme.light});
  background-size: cover;
  background-position: top;
  min-height: 100%;
  position: relative;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const GlassCardAnimateRight = styled(Card)<{ darkMode: boolean }>`
  background-color: ${(props) =>
    props.darkMode ? "rgba(255, 255, 255, 0.85)" : "rgba(15, 2, 70, 0.85)"};
  padding: 2em;
  box-shadow: 0px 2px 10px 0px #ffffff;
  max-width: 500px;
  transition: transform 0.5s;
  border-bottom-left-radius: 2em;
  align-self: flex-start;
  &:hover {
    transform: translate(4em, 0%);
  }
  @media (max-width: 865px) {
    &:hover {
      transform: none;
    }
  }
`;

export const GlassCardAnimateLeft = styled(Card)<{ darkMode: boolean }>`
  background-color: ${(props) =>
    props.darkMode ? "rgba(255, 255, 255, 0.85)" : "rgba(15, 2, 70, 0.85)"};
  padding: 2em;
  box-shadow: 0px 2px 10px 0px #ffffff;
  transition: transform 0.5s;
  max-width: 500px;
  border-bottom-left-radius: 2em;
  align-self: flex-end;
  &:hover {
    transform: translate(-4em, 0%);
  }
  @media (max-width: 865px) {
    &:hover {
      transform: none;
    }
  }
`;

export const OregonText = styled(Typography)`
  font-size: 22px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.hoverGray};
  flex-grow: 1;
`;

export const LocalWrapper = styled.div<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  flex-direction: ${(props) => (props.mobile ? "row" : "column")};
  gap: 10px;
  padding: 20px;
  max-width: 867px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: red;
`;

export const MainText = styled(Typography)<{ darkmode: boolean }>`
  font-size: 45px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-weight: bold !important;
`;

export const SubText = styled(Typography)<{ darkmode: boolean }>`
  font-size: 18px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
`;
