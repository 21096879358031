import { useContext } from "react";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import * as ResygnalStyled from "../../ResygnalStyled";
import { privacySections } from "./PrivacyPolicy.constants";
import { ThemeContext } from "../../App";

export const PrivacyPolicy = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <PageWrapper useLightMode={!themeContext.darkMode}>
      <ResygnalStyled.PageHeader light={!themeContext.darkMode}>
        Privacy Policy
      </ResygnalStyled.PageHeader>
      <>
        {privacySections.map((section, idx) => (
          <>
            <ResygnalStyled.RegularText
              key={idx}
              light={!themeContext.darkMode}
            >
              {section.header}
            </ResygnalStyled.RegularText>
            <ResygnalStyled.SecondaryText light={!themeContext.darkMode}>
              {section.content}
            </ResygnalStyled.SecondaryText>
            <br />
          </>
        ))}
      </>
    </PageWrapper>
  );
};
