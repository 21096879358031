import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ResygnalTheme } from "./ResygnalTheme";

export const PageHeader = styled(Typography)<{ light?: boolean }>`
  font-size: 45px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) => (props.light ? ResygnalTheme.light : ResygnalTheme.dark)};
  font-weight: bold !important;
  margin-bottom: 20px;
`;

export const RegularText = styled(Typography)<{ light?: boolean }>`
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) => (props.light ? ResygnalTheme.light : ResygnalTheme.dark)};
  font-size: 14px !important;
`;

export const SecondaryText = styled(Typography)<{ light?: boolean }>`
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) => (props.light ? ResygnalTheme.light : ResygnalTheme.dark)};
  font-size: 14px !important;
`;
