export const privacySections = [
  {
    header: "Introduction",
    content:
      'This privacy policy outlines the information collection, use, and disclosure practices of ReSygnal ("LLC") in the state of Oregon. The Company is committed to protecting the privacy of its users, and this policy is designed to inform you of your rights and our responsibilities with respect to the collection, use, and disclosure of your personal information.',
  },
  {
    header: "Information Collection",
    content:
      "The Company collects personal information such as your name, email address, and phone number. We may also collect non-personal information such as your IP address, browser type, and access times when you visit our website.",
  },
  {
    header: "Use of Information",
    content:
      "The Company uses the personal information we collect to provide you with our products and services, process payments, and communicate with you. We may also use your personal information to send you promotional offers or newsletters. The non-personal information we collect is used to improve our website and services, analyze trends, and track user behavior.",
  },
  {
    header: "Disclosure of Information",
    content:
      "The Company does not sell or rent your personal information to third parties. We may share your personal information with our trusted service providers who assist us in providing our products and services. We may also disclose your personal information to law enforcement agencies or other third parties when required by law or to protect our legal rights. The Company may also disclose non-personal information to third parties for marketing, advertising, or other purposes.",
  },
];
