import { useContext } from "react";
import * as Styled from "./Footer.styled";
import { ThemeContext } from "../../App";
import ReSygnalDark from "../../assets/resygnal-logos/ReSygnal_Dark.svg";
import ReSygnalLight from "../../assets/resygnal-logos/ReSygnal_Light.svg";
import { useMediaQuery } from "@mui/material";
import { Instagram, LinkedIn } from "@mui/icons-material";
import { ResygnalTheme } from "../../ResygnalTheme";
import XLogoWhite from "../../assets/logo-white.png";
import XLogoBlack from "../../assets/logo-black.png";

export const Footer = () => {
  const mobile = useMediaQuery("(min-width:835px)");
  const themeContext = useContext(ThemeContext);

  return (
    <Styled.Wrapper darkMode={themeContext.darkMode}>
      {mobile && (
        <Styled.Logo
          src={themeContext.darkMode ? ReSygnalDark : ReSygnalLight}
          alt="ReSygnal"
        />
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 50,
          marginBottom: mobile ? 0 : 40,
        }}
      >
        <Styled.Sections>
          <Styled.Section>
            <Styled.HeaderText useLight={!themeContext.darkMode}>
              Services
            </Styled.HeaderText>
            <Styled.ThemedLink to="/#software-development">
              <Styled.RegularText isLink useLight={themeContext.darkMode}>
                Software Development
              </Styled.RegularText>
            </Styled.ThemedLink>
            <Styled.ThemedLink to="/#cloud-hosting">
              <Styled.RegularText isLink useLight={themeContext.darkMode}>
                Cloud Hosting
              </Styled.RegularText>
            </Styled.ThemedLink>
            <Styled.ThemedLink to="/#it-services-and-consulting">
              <Styled.RegularText isLink useLight={themeContext.darkMode}>
                IT Services & Consulting
              </Styled.RegularText>
            </Styled.ThemedLink>
          </Styled.Section>
        </Styled.Sections>
        <Styled.Sections>
          <Styled.Section>
            <Styled.HeaderText useLight={!themeContext.darkMode}>
              Company
            </Styled.HeaderText>
            <Styled.ThemedLink to="/faq">
              <Styled.RegularText useLight={themeContext.darkMode} isLink>
                FAQ
              </Styled.RegularText>
            </Styled.ThemedLink>
            <Styled.ThemedLink to="/about">
              <Styled.RegularText useLight={themeContext.darkMode} isLink>
                About
              </Styled.RegularText>
            </Styled.ThemedLink>
            <Styled.ThemedLink to="/privacy-policy">
              <Styled.RegularText useLight={themeContext.darkMode} isLink>
                Privacy Policy
              </Styled.RegularText>
            </Styled.ThemedLink>
          </Styled.Section>
        </Styled.Sections>
        <Styled.Sections style={{ alignSelf: "flex-end", marginBottom: 8 }}>
          <a
            rel="noreferrer"
            href="https://www.linkedin.com/company/resygnal/"
            target="_blank"
          >
            <LinkedIn
              style={{
                cursor: "pointer",
                color: themeContext.darkMode
                  ? ResygnalTheme.dark
                  : ResygnalTheme.light,
              }}
            />
          </a>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/resygnal/"
            target="_blank"
          >
            <Instagram
              style={{
                cursor: "pointer",
                color: themeContext.darkMode
                  ? ResygnalTheme.dark
                  : ResygnalTheme.light,
              }}
            />
          </a>
          <a
            rel="noreferrer"
            href="https://x.com/resygnal"
            target="_blank"
            style={{ marginTop: 3 }}
          >
            <img
              src={themeContext.darkMode ? XLogoBlack : XLogoWhite}
              style={{ cursor: "pointer", width: 18 }}
            />
          </a>
        </Styled.Sections>
      </div>
    </Styled.Wrapper>
  );
};
