import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import * as Styled from "../landing/Landing.styled";
import * as FaqStyled from "./Faq.styled";
import { FaqArr } from "./Faq.constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as ResygnalStyled from "../../ResygnalStyled";
import { useContext, useState } from "react";
import { FaqT } from "./Faq.types";
import { ThemeContext } from "../../App";
import { ResygnalTheme } from "../../ResygnalTheme";

export const Faq = () => {
  const themeContext = useContext(ThemeContext);
  const [filter, setFilter] = useState<string>();

  const filterStep = (step: FaqT) => {
    if (!filter) {
      return step;
    }

    if (
      step.question.toLowerCase().includes(filter.toLowerCase()) ||
      step.answer.toLowerCase().includes(filter.toLowerCase())
    ) {
      return step;
    }
  };

  const results = FaqArr.filter((step) => filterStep(step));

  return (
    <PageWrapper useLightMode={!themeContext.darkMode}>
      <FaqStyled.Wrapper darkMode={themeContext.darkMode}>
        <Styled.FlexWrapper>
          <FaqStyled.MainText darkMode={!themeContext.darkMode}>
            Frequently asked questions
          </FaqStyled.MainText>
          <Styled.SubText darkmode={!themeContext.darkMode}>
            We've got you covered
          </Styled.SubText>
          <FaqStyled.TextInput
            darkMode={!themeContext.darkMode}
            placeholder="Search..."
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaqStyled.ThemedSearch darkMode={!themeContext.darkMode} />
                </InputAdornment>
              ),
              endAdornment: filter && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setFilter("")}>
                    <FaqStyled.ThemedClose darkMode={!themeContext.darkMode} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div>
            {!results.length ? (
              <Styled.SubText darkmode={!themeContext.darkMode}>
                No Results
              </Styled.SubText>
            ) : (
              <>
                {results.map((step, idx) => (
                  <Accordion
                    key={idx}
                    style={{
                      backgroundColor: themeContext.darkMode
                        ? ResygnalTheme.dark
                        : ResygnalTheme.light,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: !themeContext.darkMode
                              ? ResygnalTheme.dark
                              : ResygnalTheme.light,
                          }}
                        />
                      }
                      aria-controls={`panel1a${idx}-content`}
                      id={`panel1a${idx}-header`}
                    >
                      <ResygnalStyled.RegularText light={themeContext.darkMode}>
                        {step.question}
                      </ResygnalStyled.RegularText>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ResygnalStyled.SecondaryText
                        light={themeContext.darkMode}
                      >
                        {step.answer}
                      </ResygnalStyled.SecondaryText>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </div>
        </Styled.FlexWrapper>
      </FaqStyled.Wrapper>
    </PageWrapper>
  );
};
