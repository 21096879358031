import styled from "@emotion/styled";
import { AlertTitle } from "@mui/material";

export const AlertWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
`;

export const AlertMessage = styled(AlertTitle)`
  margin: 0;
`;
