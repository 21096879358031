export interface FormValues {
  name?: string;
  email?: string;
  number?: string;
  typeOfWork?: Array<string>;
  message?: string;
}

export interface ConnectProps {
  useLightMode?: boolean;
  isFooter?: boolean;
  onDialogClose?: () => void;
  openProp?: boolean;
}

export interface ConnectResponse {
  error?: string;
}

export enum PrettyError {
  TOKEN = "Invalid Recaptcha Token",
  INTERNAL_SERVER = "Internal Server Error",
  BAD_REQUEST = "Bad Request",
}

export enum StatusCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER = 500,
}
