import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import * as Styled from "./About.styled";
import * as LandingStyled from "../landing/Landing.styled";
import { useContext } from "react";
import { ThemeContext } from "../../App";
import { Box } from "@mui/material";
import Hunting from "../../assets/hunting.jpg";

export const About = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <PageWrapper
      childWrapperStyled={{ paddingLeft: 0, paddingRight: 0 }}
      useLightMode={!themeContext.darkMode}
    >
      <Box paddingX={"10%"}>
        <LandingStyled.MainText darkmode={!themeContext.darkMode}>
          About Us
        </LandingStyled.MainText>

        <Box display="flex" flexWrap="wrap" mt="30px">
          <Styled.SubText darkmode={!themeContext.darkMode}>
            Located in the Pacific Northwest, ReSygnal is a team of professional
            designers, engineers, and software architects. Our diverse
            background in software helps us create a wide variety of solutions.{" "}
            <br />
            <br /> We understand the importance of having a reliable and
            efficient software system that meets your business needs. We
            specialize in creating custom software solutions that are tailored
            to your business requirements, ensuring that your organization
            operates smoothly and efficiently. <br />
            <br /> Our team of experienced developers and designers work closely
            with you to understand your business goals and requirements, and
            then develop solutions that are tailored to meet those needs.
            Whether you need a simple website, a mobile application, or a
            complex enterprise software system, we can deliver quality products
            that meet your specifications.
          </Styled.SubText>
          <div
            style={{
              backgroundImage: `url(${Hunting})`,
              backgroundSize: "cover",
              width: "100%",
              height: "auto",
              minHeight: "600px",
              backgroundPosition: "bottom",
              marginTop: 30,
              borderRadius: 10,
            }}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};
