import {
  ConnectResponse,
  FormValues,
  PrettyError,
  StatusCodes,
} from "../../pages/connect/Connect.types";

export async function ConnectFn(
  formValues: FormValues,
  secret: string
): Promise<ConnectResponse> {
  return new Promise(async function (resolve) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formValues),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/connect?response=${secret}`,
        requestOptions
      );

      if (response.status !== StatusCodes.OK) {
        switch (response.status) {
          case StatusCodes.BAD_REQUEST:
            throw PrettyError.BAD_REQUEST;
          case StatusCodes.UNAUTHORIZED:
            throw PrettyError.TOKEN;
          default:
            throw PrettyError.INTERNAL_SERVER;
        }
      }

      response.json().then((data) => {
        if (!data.errors) {
          throw PrettyError.BAD_REQUEST;
        }

        if (data.errors.length) {
          throw String(data.errors.join(", "));
        }
      });

      return resolve({});
    } catch (e) {
      console.error(e);
      return resolve({ error: String(e) });
    }
  });
}
