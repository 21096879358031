import styled from "@emotion/styled";
import { TextField, Typography } from "@mui/material";
import { ResygnalTheme } from "../../ResygnalTheme";
import { Close, Search } from "@mui/icons-material";

export const Wrapper = styled.div<{ darkMode: boolean }>`
  padding: 20px;
  background-color: ${(props) =>
    props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const MainText = styled(Typography)<{ darkMode: boolean }>`
  font-size: 45px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-weight: bold !important;
`;

export const ThemedSearch = styled(Search)<{ darkMode: boolean }>`
  font-size: 20px;
  color: ${(props) =>
    props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-weight: bold !important;
`;

export const ThemedClose = styled(Close)<{ darkMode: boolean }>`
  font-size: 20px;
  color: ${(props) =>
    props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-weight: bold !important;
`;

export const TextInput = styled(TextField)<{ darkMode: boolean }>`
  .MuiInputBase-input-MuiOutlinedInput-input {
    color: ${(props) =>
      props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark} !important;
  }

  & label.Mui-focused {
    color: ${(props) =>
      props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${(props) =>
      props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
  }
  & .MuiOutlinedInput-root {
    font-family: "Ubuntu", sans-serif !important;
    color: ${(props) =>
      props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
    height: 45px;
    & fieldset {
      border-color: ${(props) =>
        props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
      color: ${(props) =>
        props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
    }
    &:hover fieldset {
      border-color: ${(props) =>
        props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) =>
        props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
    }
  }
`;
