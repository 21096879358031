import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ResygnalTheme } from "../../ResygnalTheme";

export const HeaderText = styled(Typography)<{ useLight?: boolean }>`
  font-size: 17px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.useLight ? ResygnalTheme.light : ResygnalTheme.dark};
  max-width: 80%;
  margin-bottom: 5px;
`;

export const RegularText = styled(Typography)<{
  useLight?: boolean;
  isLink?: boolean;
}>`
  font-size: 14px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.useLight ? ResygnalTheme.dark : ResygnalTheme.light};
  margin-bottom: 8px;

  ${(props) =>
    props.isLink
      ? `&:hover {
    color: grey;
    cursor: pointer;
  }`
      : undefined}
`;

export const Wrapper = styled.div<{ darkMode: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  background-color: ${(props) =>
    props.darkMode ? ResygnalTheme.light : ResygnalTheme.dark};
  padding: 50px 10%;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  width: 100px;
`;

export const Sections = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
  justify-content: center;
`;

export const Section = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 120px;
`;

export const ThemedLink = styled(Link)`
  text-decoration: none;
`;

export const Logo = styled.img`
  width: 125px;
  vertical-align: bottom;
`;
