import { useMediaQuery } from "@mui/material";
import * as Styled from "./Landing.styled";
import { ResygnalTheme } from "../../ResygnalTheme";
import Snowfall from "react-snowfall";
import { useContext, useEffect, useState } from "react";
import { RocketLaunch } from "@mui/icons-material";
import NavigationIcon from "@mui/icons-material/Navigation";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import Iphone from "../../assets/iPhone.svg";
import Color from "../../assets/colorful.svg";
import Team from "../../assets/team.svg";
import Service from "../../assets/services.svg";
import Hero from "../../assets/hero.svg";
import HeroDark from "../../assets/heroDark.svg";

import { StepsArr } from "../services/Services.constants";
import { ThemeContext } from "../../App";
import { Navbar } from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";
import FadeIn from "react-fade-in";
import { useLocation } from "react-router-dom";

export const Landing = () => {
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const mobile = useMediaQuery("(min-width:1106px)");
  const flex1 = useMediaQuery("(min-width:947px)");
  const buttonGrow = useMediaQuery("(min-width:500px)");
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash);
      element?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [location]);

  const listenToScroll = () => {
    setIsScrolled(
      (document.body.scrollTop || document.documentElement.scrollTop) > 0
    );
  };

  return (
    <div>
      {isScrolled && (
        <Styled.ThemedLink
          to="/#top"
          style={{ position: "fixed", bottom: 20, right: 20, zIndex: 10 }}
        >
          <Styled.ButtonSolid
            darkmode={!themeContext.darkMode}
            buttonGrow={!buttonGrow}
          >
            <NavigationIcon sx={{ mr: 1 }} />
            Top
          </Styled.ButtonSolid>
        </Styled.ThemedLink>
      )}
      <div id="#top" />
      <Navbar useLightMode={themeContext.darkMode} />
      <FadeIn>
        <div
          style={{
            display: "flex",
            zIndex: 1,
            gap: 20,
            maxWidth: 1800,
            padding: !flex1 ? "20px 40px" : "20px 120px",
            backgroundColor: themeContext.darkMode
              ? ResygnalTheme.dark
              : ResygnalTheme.light,
            flexWrap: "wrap",
            justifyContent: !mobile ? "center" : "normal",
          }}
        >
          <div style={{ flex: 1, alignSelf: "center" }}>
            <Styled.MainText darkmode={themeContext.darkMode}>
              <span
                style={{
                  color: !themeContext.darkMode
                    ? "rgba(0,0,0,0.6)"
                    : "rgba(255,255,255,0.6)",
                }}
              >
                ReInvent.{" "}
              </span>
              <span
                style={{
                  color: !themeContext.darkMode
                    ? "rgba(0,0,0,0.6)"
                    : "rgba(255,255,255,0.6)",
                }}
              >
                ReArchitect.
              </span>{" "}
              ReSygnal.
            </Styled.MainText>
            <Styled.MainSubText darkmode={themeContext.darkMode}>
              At ReSygnal, we build software to make your business more
              efficient, resilient, and prepared for the future.
            </Styled.MainSubText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                marginTop: 25,
                flexWrap: "wrap",
              }}
            >
              <Styled.ThemedLink to="/#software-development">
                <Styled.ButtonSolid
                  darkmode={themeContext.darkMode}
                  buttonGrow={!buttonGrow}
                >
                  Software Development
                </Styled.ButtonSolid>
              </Styled.ThemedLink>
              <Styled.ThemedLink to="/#cloud-hosting">
                <Styled.ButtonSolid
                  darkmode={themeContext.darkMode}
                  buttonGrow={!buttonGrow}
                >
                  Cloud Hosting
                </Styled.ButtonSolid>
              </Styled.ThemedLink>
              <Styled.ThemedLink to="/#it-services-and-consulting">
                <Styled.ButtonSolid
                  darkmode={themeContext.darkMode}
                  buttonGrow={!buttonGrow}
                >
                  IT Services & Consulting
                </Styled.ButtonSolid>
              </Styled.ThemedLink>
            </div>
          </div>
          {/* <img
            src={!themeContext.darkMode ? Hero : HeroDark}
            alt="iphone"
            style={{ flex: 1, width: "100%", minWidth: 225, marginTop: 20 }}
          /> */}
        </div>
        <svg
          style={{
            backgroundColor: !themeContext.darkMode
              ? ResygnalTheme.dark
              : ResygnalTheme.light,
            position: "relative",
            zIndex: -1,
            marginTop: -5,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          shapeRendering="crispEdges"
        >
          <path
            shapeRendering="crispEdges"
            fill={
              themeContext.darkMode ? ResygnalTheme.dark : ResygnalTheme.light
            }
            fill-opacity="1"
            d="M0,32L40,53.3C80,75,160,117,240,133.3C320,149,400,139,480,122.7C560,107,640,85,720,106.7C800,128,880,192,960,192C1040,192,1120,128,1200,101.3C1280,75,1360,85,1400,90.7L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg>
        <div
          style={{
            // minHeight: "calc(100% - 250px)",
            marginTop: -5,
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: 30,
            backgroundColor: !themeContext.darkMode
              ? ResygnalTheme.dark
              : ResygnalTheme.light,
          }}
        >
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <div
              style={{
                display: "flex",
                marginBottom: 100,
                gap: 30,
                justifyContent: !flex1 ? "center" : "space-around",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  maxWidth: flex1 ? 425 : undefined,
                }}
              >
                <Styled.SubSectionText
                  darkmode={!themeContext.darkMode}
                  id="#software-development"
                >
                  Software Development
                </Styled.SubSectionText>
                <Styled.SubText
                  style={{ marginTop: 20 }}
                  darkmode={!themeContext.darkMode}
                >
                  Custom application development from ideation to production.
                  Our expert developers combine cutting-edge technology and
                  functional design to expand your business impact.
                </Styled.SubText>
              </div>
              <img
                src={Color}
                alt="color"
                style={{ width: flex1 ? "40%" : "60%", minWidth: 250 }}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <div
              style={{
                display: "flex",
                marginBottom: 100,
                gap: 30,
                justifyContent: !flex1 ? "center" : "space-around",
                flexWrap: "wrap",
              }}
            >
              {flex1 && (
                <img
                  src={Service}
                  alt="team"
                  style={{ width: flex1 ? "40%" : "60%", minWidth: 250 }}
                />
              )}
              <div
                style={{
                  alignSelf: "center",
                  maxWidth: flex1 ? 425 : undefined,
                }}
              >
                <Styled.SubSectionText
                  darkmode={!themeContext.darkMode}
                  id="#cloud-hosting"
                >
                  Cloud Hosting
                </Styled.SubSectionText>
                <Styled.SubText
                  style={{ marginTop: 20 }}
                  darkmode={!themeContext.darkMode}
                >
                  Moving to the cloud but not sure where to start? We’ve
                  simplified the shift to cloud so you can focus on your
                  business. Whether you want to run your own servers or let us
                  handle the difficult parts, ReSygnal is here to partner with
                  you.
                </Styled.SubText>
              </div>
              {!flex1 && (
                <img
                  src={Service}
                  alt="team"
                  style={{ width: flex1 ? "40%" : "60%", minWidth: 250 }}
                />
              )}
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <div
              style={{
                display: "flex",
                marginBottom: 100,
                gap: 30,
                justifyContent: !flex1 ? "center" : "space-around",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  maxWidth: flex1 ? 425 : undefined,
                }}
              >
                <Styled.SubSectionText
                  darkmode={!themeContext.darkMode}
                  id="#it-services-and-consulting"
                >
                  IT Services & Consulting
                </Styled.SubSectionText>
                <Styled.SubText
                  style={{ marginTop: 20 }}
                  darkmode={!themeContext.darkMode}
                >
                  Optimize your business with our seamless IT and system
                  integration solutions. Our experts unify diverse systems and
                  applications, enabling efficient data flow, automation, and
                  collaboration. Experience real-time insights and streamlined
                  processes, empowering your business to reach new heights.
                </Styled.SubText>
              </div>
              <img
                src={Team}
                alt="service"
                style={{ width: "40%", minWidth: 300 }}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <Styled.Process>
              <Styled.ProcessText
                darkmode={!themeContext.darkMode}
                style={{ textAlign: "center" }}
              >
                How our process works
              </Styled.ProcessText>
              <RocketLaunch
                style={{
                  color: themeContext.darkMode
                    ? ResygnalTheme.dark
                    : ResygnalTheme.light,
                }}
              />
            </Styled.Process>
            <>
              {StepsArr.map((step, idx) => (
                <Styled.StepService darkmode={!themeContext.darkMode}>
                  Step {idx + 1}. {step}
                </Styled.StepService>
              ))}
            </>
          </ScrollAnimation>

          <Styled.SnowWrapper>
            <Styled.Divide />
            <Snowfall
              color={
                themeContext.darkMode ? ResygnalTheme.dark : ResygnalTheme.light
              }
              snowflakeCount={20}
              speed={[0.2, 0.2]}
            />
            <Styled.Ending darkmode={!themeContext.darkMode}>
              Lets build the software you deserve
            </Styled.Ending>
          </Styled.SnowWrapper>
        </div>
      </FadeIn>
      <Footer />
    </div>
  );
};
