import { Service } from "./Services.types";

export const ServicesArr: Array<Service> = [
  {
    title: "Websites",
    description:
      "Static websites to full-scale dynamic  E-commerce and social media web applications",
    bullets: ["Business Sites", "Social Media", "Blogs", "E-commerce"],
    queryString: "web",
  },
  {
    title: "Mobile Apps",
    description:
      "We specialize in mobile app development to target all size devices",
    bullets: ["Social Media", "Games", "Fitness"],
    queryString: "mobile",
  },
  {
    title: "Cloud Services",
    description:
      "We can create any kind of API using many different software languages",
    bullets: ["Product Inventory", "Realtime Updates", "Data Backups"],
    queryString: "api",
  },
  {
    title: "Project Architecture",
    description:
      "From entire project overview to simple UML diagrams. We can help create a scalable architecture for your project",
    bullets: ["UML", "Wire framing", "Scalable Infrastructure"],
    queryString: "architecture",
  },
];

export const StepsArr: Array<string> = [
  "Discuss an issue or an idea with our team",
  "Our team will analyze the problem or idea and come back with a possible solution",
  "If you and your team like our solution, we can proceed with a specialized contract and begin development",
];
