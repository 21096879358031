import * as Yup from "yup";
import { FormValues } from "./Connect.types";

const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const yupSchema = () =>
  Yup.object<FormValues>({
    name: Yup.string().min(3).required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    number: Yup.string()
      .matches(PHONE_REGEX, "Invalid Number")
      .required("Required"),
  });
