import styled from "@emotion/styled";
import { Close, Send } from "@mui/icons-material";
import { Button, Checkbox, TextField, Typography } from "@mui/material";
import { ResygnalTheme } from "../../ResygnalTheme";

export const SubSectionText = styled(Typography)`
  font-size: 22px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.dark};
`;

export const Wrapper = styled.div`
  padding: 40px;
  background-color: ${ResygnalTheme.light};
`;

export const SubText = styled(Typography)`
  font-size: 14px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.dark};
`;

export const FieldLabel = styled(Typography)`
  font-size: 13px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.dark};
  margin-top: 10px;
  margin-bottom: 2px;
`;

export const TextInput = styled(TextField)<{ multiLine?: boolean }>`
  & .MuiInputBase-root {
    height: ${(props) => (props.multiLine ? "inherit" : "40px")};
  }
  & .MuiInputBase-input {
    color: ${ResygnalTheme.dark};
    font-size: 14px;
    height: 40px;
  }
  & label {
    color: ${ResygnalTheme.dark};
  }
  & label.Mui-focused {
    color: ${ResygnalTheme.dark};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${ResygnalTheme.dark};
  }

  & .MuiOutlinedInput-root {
    font-family: "Ubuntu", sans-serif !important;
    & fieldset {
      border-color: ${ResygnalTheme.dark};
    }

    &:hover fieldset {
      border-color: ${ResygnalTheme.hoverGray};
      &.Mui-disabled {
        border-color: ${ResygnalTheme.disabled};
      }
    }

    &.Mui-disabled fieldset {
      color: ${ResygnalTheme.disabled};
    }

    &.Mui-focused fieldset {
      border-color: ${ResygnalTheme.dark};
    }
  }
`;

export const ButtonSolid = styled(Button)`
  margin-top: 25px;
  font-family: "Ubuntu", sans-serif !important;
  height: 40px;
  background-color: ${ResygnalTheme.dark} !important;
  text-transform: none !important;
  color: ${ResygnalTheme.light};

  &.Mui-disabled {
    color: ${ResygnalTheme.disabled};
  }
`;

export const ThemedSend = styled(Send)`
  font-size: 17px;
  color: ${ResygnalTheme.light};
`;

export const CheckField = styled(Checkbox)`
  color: ${ResygnalTheme.dark};
`;

export const ControlLabel = styled(Typography)`
  color: ${ResygnalTheme.dark};
  font-size: 14px !important;
  font-family: "Ubuntu", sans-serif !important;
`;

export const ErrorMessage = styled(Typography)`
  margin-top: 15px;
  color: ${ResygnalTheme.error};
  font-size: 13px !important;
  font-family: "Ubuntu", sans-serif !important;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const ThemedClose = styled(Close)`
  color: ${ResygnalTheme.dark};
`;
