import { Alert } from "@mui/material";
import { AlertProps } from "./Alert.types";
import * as Styled from "./Alert.styled";

export default function AlertToaster({ message, severity }: AlertProps) {
  return (
    <Styled.AlertWrapper>
      <Alert severity={severity}>
        <Styled.AlertMessage>{message}</Styled.AlertMessage>
      </Alert>
    </Styled.AlertWrapper>
  );
}
