import { Box, Stack, useMediaQuery } from "@mui/material";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { useContext } from "react";
import { ThemeContext } from "../../App";
import * as Styled from "../landing/Landing.styled";
import NownWhite from "../../assets/enoun-logos/WhiteColor.svg";
import NownBlack from "../../assets/enoun-logos/BlackColor.svg";
import Phone from "../../assets/enoun-mockups/phone.png";
import Mac from "../../assets/enoun-mockups/mac.png";
import PhoneDark from "../../assets/enoun-mockups/phone-dark.png";
import MacDark from "../../assets/enoun-mockups/mac-dark.png";

export const Products = () => {
  const mobile = useMediaQuery("(min-width:900px)");
  const themeContext = useContext(ThemeContext);

  return (
    <PageWrapper useLightMode={!themeContext.darkMode}>
      <Styled.MainText darkmode={!themeContext.darkMode}>
        Meet Our Products
      </Styled.MainText>
      <Box display="flex" justifyContent="space-between">
        <Stack>
          <img
            alt="logo"
            style={{ width: 150, marginTop: 30, marginBottom: 15 }}
            src={!themeContext.darkMode ? NownWhite : NownBlack}
          />
        </Stack>
        <Stack my="auto">
          <a
            rel="noreferrer"
            href="https://marketing.enoun.app"
            target="_blank"
          >
            <Styled.ButtonSolid darkmode={!themeContext.darkMode}>
              Visit eNoun
            </Styled.ButtonSolid>
          </a>
        </Stack>
      </Box>
      <Styled.SubText darkmode={!themeContext.darkMode}>
        eNoun is a versatile software tool designed to track people, places, and
        things efficiently. Whether you're managing a team, monitoring
        locations, or keeping tabs on important items, eNoun offers seamless
        tracking capabilities to ensure you stay organized and informed. With
        user-friendly features, real-time updates, and a growing app library for
        new integrations, eNoun is your go-to solution for comprehensive
        tracking and management.
      </Styled.SubText>
      <Box
        justifyContent="space-around"
        display="flex"
        marginTop={7}
        paddingBottom={5}
        gap={10}
        flexWrap="wrap"
      >
        <img
          alt="mac"
          style={{
            height: mobile ? "100%" : undefined,
            width: !mobile ? "100%" : undefined,
            maxHeight: 400,
          }}
          src={!themeContext.darkMode ? MacDark : Mac}
        />
        <img
          alt="iphone"
          style={{ maxWidth: 200 }}
          src={!themeContext.darkMode ? PhoneDark : Phone}
        />
      </Box>
    </PageWrapper>
  );
};
