import { FaqT } from "./Faq.types";

export const FaqArr: Array<FaqT> = [
  {
    question: "How does the design process work?",
    answer:
      "A simple design process typically involves several stages, starting with defining the problem and identifying the needs and goals of the project. With this information, our team can start creating wire frames and solutions that best align with your end goal.",
  },
  {
    question: "Can I have the code when the project is over?",
    answer: "Yes. We give this option to all clients of ours.",
  },
  {
    question: "Whats the support time after the project is completed?",
    answer:
      "Typically we like to support a product for 3 months from the completion date, we can adjust to whatever your needs are during the proposal and contract process.",
  },
  {
    question: "How long does the development process take?",
    answer:
      "Unfortunately, each development cycle is independent from one another and there are many factors that effect the completion time for a project.",
  },
  {
    question: "Why don't I use a website builder instead?",
    answer:
      "Website builders are great, but they do not work for everyone. At ReSygnal, we strive to make unique and cutting edge software products that will help you and your business needs.",
  },
];
