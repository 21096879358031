import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";

//Routes
import { Landing } from "./pages/landing/Landing";
import { Services } from "./pages/services/Services";
import { Faq } from "./pages/faq/Faq";
import { PrivacyPolicy } from "./pages/privacyPolicy/PrivacyPolicy";
import { About } from "./pages/about/About";
import { Fab } from "@mui/material";
import { ResygnalTheme } from "./ResygnalTheme";
import { Nightlight, WbSunny } from "@mui/icons-material";
import { GridLoader, PacmanLoader, RingLoader } from "react-spinners";
import { Products } from "./pages/products/Products";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

export const ThemeContext = createContext<{ darkMode: boolean }>({
  darkMode: false,
});

export const App = () => {
  const [theme, setTheme] = useState<{ darkMode: boolean }>({
    darkMode: false,
  });

  return (
    <ThemeContext.Provider value={theme}>
      <Fab
        aria-label="add"
        style={{
          position: "fixed",
          bottom: 15,
          left: 15,
          backgroundColor: theme.darkMode
            ? ResygnalTheme.dark
            : ResygnalTheme.light,
        }}
        onClick={() => setTheme({ darkMode: !theme.darkMode })}
      >
        {theme.darkMode ? (
          <WbSunny style={{ color: ResygnalTheme.light }} />
        ) : (
          <Nightlight style={{ color: ResygnalTheme.dark }} />
        )}
      </Fab>
      <RouterProvider router={router} />
    </ThemeContext.Provider>
  );
};
