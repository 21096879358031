import {
  CenterFocusWeak,
  Code,
  Email,
  LandscapeOutlined,
  Send,
} from "@mui/icons-material";
import { Button, Divider, TextField, Typography } from "@mui/material";
import styled, { keyframes } from "styled-components";
import { ResygnalTheme } from "../../ResygnalTheme";
import { Link as DomLink } from "react-router-dom";

export const Title = styled(Typography)`
  font-size: 20px !important;
  font-family: "Montserrat Subrayada", sans-serif !important;
  color: Theme;
`;

export const Link = styled(Typography)`
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.dark};
  font-size: 15px;
`;

export const MainText = styled(Typography)<{ darkmode: boolean }>`
  font-size: 38px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-weight: bold !important;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const MainSubText = styled(Typography)<{ darkmode: boolean }>`
  margin-top: 15px !important;
  font-size: 22px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const SubText = styled(Typography)<{ darkmode: boolean }>`
  font-size: 18px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const ProcessText = styled(Typography)<{ darkmode: boolean }>`
  font-size: 32px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const SubSectionText = styled(Typography)<{ darkmode: boolean }>`
  font-size: 22px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const SectionStep = styled.div`
  padding: 20px;
`;

export const ThemedDivider = styled(Divider)`
  background-color: ${ResygnalTheme.light};
  margin-bottom: 25px;
  margin-top: 15px;
`;

export const SubTextStep = styled(Typography)<{ darkmode: boolean }>`
  font-size: 14px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
`;

export const StepService = styled(Typography)<{ darkmode: boolean }>`
  font-family: "Ubuntu", sans-serif !important;
  padding-top: 50px;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
  font-size: 18px !important;
`;

export const Process = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const CarouselTitle = styled(Typography)`
  font-size: 28px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.light};
`;

export const OregonText = styled(Typography)`
  font-size: 22px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.hoverGray};
  flex-grow: 1;
`;

export const CarouselSmallText = styled(Typography)`
  font-size: 15px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.light};
  max-width: 80%;
`;

export const BigCardText = styled(Typography)`
  font-size: 15px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: #666666;
  max-width: 80%;
`;

export const CarouselParagraph = styled(Typography)`
  font-size: 18px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.light};
`;

export const SectionParagraph = styled(Typography)`
  font-size: 18px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.dark};
  font-weight: bold !important;
`;

export const ListItemHeader = styled(Typography)`
  margin-top: 15px !important;
  font-size: 38px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.dark};
`;

export const ListItem = styled.li`
  color: ${ResygnalTheme.dark};
  font-family: "Ubuntu", sans-serif !important;
  font-size: 28px;
`;

export const Card = styled.div`
  background: rgba(255, 255, 255, 0.61);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background-color: rgba(98, 44, 44, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.09);
`;

export const TextInput = styled(TextField)`
  & label.Mui-focused {
    color: ${ResygnalTheme.dark};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${ResygnalTheme.dark};
  }
  & .MuiOutlinedInput-root {
    font-family: "Ubuntu", sans-serif !important;
    height: 45px;
    & fieldset {
      border-color: ${ResygnalTheme.dark};
    }
    &:hover fieldset {
      border-color: ${ResygnalTheme.dark};
    }
    &.Mui-focused fieldset {
      border-color: ${ResygnalTheme.dark};
    }
  }
`;

export const ButtonSolid = styled(Button)<{
  darkmode: boolean;
  buttonGrow?: boolean;
}>`
  font-family: "Ubuntu", sans-serif !important;
  /* height: 45px; */
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.dark : ResygnalTheme.light} !important;
  background-color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark} !important;
  text-transform: none !important;
  padding: 10px;
  flex-grow: ${(props) => (props.buttonGrow ? 1 : "none")};
  font-size: 16px !important;
`;

export const Step = styled.div`
  margin: 10px;
  background: linear-gradient(
    #d2a8ff,
    #a371f7 10%,
    #196c2e 70%,
    #2ea043 80%,
    #56d364
  );
  width: 3px;
  border-radius: 10px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingImage = styled.img`
  display: inline-block;
  animation: ${rotate} 4s linear infinite;
  padding: 5px;
  font-size: 1.2rem;
  width: 100px;
`;

export const BigCard = styled.div`
  border: 0.5px solid grey;
  border-radius: 8px;
  flex-grow: 1;
  height: 500px;
  background-color: ${ResygnalTheme.light};
  box-shadow: 0px 4px 15px 0px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  text-align: center;
  flex-direction: column;
  gap: 50px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.01);
  }
`;

export const MediumCard = styled.div`
  width: 100%;
  border: 0.5px solid grey;
  border-radius: 8px;
  flex-grow: 1;
  height: 70%;
  background-color: ${ResygnalTheme.light};
  box-shadow: 0px 4px 15px 0px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 50px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.01);
  }
`;

export const SmallCard = styled.div`
  border: 0.5px solid grey;
  border-radius: 8px;
  flex-grow: 1;
  width: 100%;
  height: 30%;
  background-color: ${ResygnalTheme.light};
  box-shadow: 0px 4px 15px 0px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 50px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.01);
  }
`;

export const FooterTitle = styled(Typography)`
  font-size: 15px !important;
  font-family: "Montserrat Subrayada", sans-serif !important;
  color: ${ResygnalTheme.light};
`;

export const LI = styled.li`
  font-family: "Ubuntu", sans-serif !important;
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  padding: 70px;
  background-color: ${ResygnalTheme.light};
  border-radius: 20px;
  margin-bottom: 150px;
`;

export const InnerWrapper = styled.div`
  height: calc(100% - 55px);
  margin: auto;
  display: flex;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 25px;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const ThemedEmail = styled(Email)`
  font-size: 20px;
`;

export const ThemedSend = styled(Send)`
  font-size: 17px;
`;

export const ThemedCenter = styled(CenterFocusWeak)`
  font-size: 30px;
`;

export const ThemedLandscape = styled(LandscapeOutlined)`
  font-size: 30px;
`;

export const ThemedCode = styled(Code)`
  color: ${ResygnalTheme.dark};
`;

export const B = styled.b`
  color: ${ResygnalTheme.dark};
  font-weight: bold;
`;

export const SectionWrapper = styled.div<{ mobile?: boolean }>`
  background: ${ResygnalTheme.light};
  margin-top: ${(props) => (props.mobile ? "40px" : "20px")};
`;

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const StepWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 30px;
  margin-bottom: 100px;
`;

export const ShadowWrapper = styled.div`
  box-shadow: 0 0 10px ${ResygnalTheme.dark};
  border: 0.5px solid grey;
  border-radius: 8px;
  margin-top: 50px;
  padding: 10px;
`;

export const CardWrapper = styled.div<{ mobile?: boolean }>`
  display: flex;
  gap: 20px;
  padding-left: ${(props) => (props.mobile ? "50px" : "0px")};
  margin-top: 50px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
`;

export const SmallWrapper = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  width: 150px;
  height: 75px;
  border: 0.5px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const UL = styled.ul`
  color: ${ResygnalTheme.light};
  list-style-type: circle;
  margin-bottom: 35px;
`;

export const EmployeeText = styled(Typography)`
  font-size: 22px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${ResygnalTheme.light};
  text-align: center;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 150px;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 20px;
`;

export const Divide = styled(Divider)`
  margin-top: 100px !important;
  margin-bottom: 100px !important;
  background-color: ${ResygnalTheme.light};
`;

export const Ending = styled(Typography)<{ darkmode: boolean }>`
  font-size: 28px !important;
  font-family: "Ubuntu", sans-serif !important;
  color: ${(props) =>
    props.darkmode ? ResygnalTheme.light : ResygnalTheme.dark};
  text-align: center;
  margin-top: 50px !important;
  padding-bottom: 150px !important;
`;

export const SnowWrapper = styled.div`
  position: relative;
`;

export const CarouselImage = styled.div`
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  flex-grow: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
`;

export const LocalWrapper = styled.div<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  flex-direction: ${(props) => (props.mobile ? "row" : "column")};
  gap: 10px;
  padding: 20px;
  max-width: 867px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

export const BottomButtons = styled.div`
  height: 5px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
`;

export const BottomButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
`;

export const ChevronButton = styled.div`
  height: calc(100% - 20px);
  position: relative;
  width: min-content;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Carousel = styled.div`
  margin-bottom: 150px;
  height: 400px;
  display: flex;
  gap: 10px;
`;

export const Animation = styled.div<{ transition: number; useRow?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.useRow ? "row" : "column")};
  flex-grow: 1;
  gap: 15px;
  opacity: ${(props) => props.transition};
  transition: all 250ms linear;
`;

export const ThemedLink = styled(DomLink)`
  text-decoration: none;
  display: flex;
`;
